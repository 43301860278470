
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Article, Reviewers } from './types'
import { parseReview, prevReviewSplitter, encodeUrl } from '@/utils/utils'
import { Card } from '@/components/card'
import { LeavingMSNAPI } from '@/api'

@Component({
  components: {
    Card,
  },
})
export default class ArticlePageReview extends Vue {
  @Prop({ required: true }) article!: Article

  get displayReview() {
    if (this.article && this.article.review) {
      // check if it has {For the text of this review, see MR0657205.} , like MR0657200
      const otherReview = /{For the text of this review, see(.[^}]*)}/gmis
      if (otherReview.test(this.article.review)) {
        return false
      }
    }
    return true
  }

  get fromPublisher() {
    // Like MR4548180
    return this.article.details.summaryUrl && this.article.details.summaryUrlDate
  }

  get encodedSummaryUrl() {
    if (!this.article.details.summaryUrl) {
      return ''
    }

    return encodeUrl(this.article.details.summaryUrl)
  }

  get prevSplitReview() {
    // return prevReviewSplitter(this.article.review)
    // MR1138725
    if (this.parsedReview) {
      return prevReviewSplitter(this.parsedReview).map((x, index) => {
        if (index > 0) {
          return `<strong>There is a newer version of this ${this.cardTitle.toLowerCase()}</strong><br>${x}`
        } else {
          return x
        }
      })
    }
    return []
  }

  get parsedReview() {
    if (this.article && this.article.review) {
      return parseReview(this.article.review)
    } else if (this.article && this.article.prePubl && this.article.prePubl.review) {
      return parseReview(this.article.prePubl.review)
    }

    return null
  }

  get reviewer(): Reviewers | null {
    if (this.article.reviewer) {
      return this.article.reviewer
    } else if (this.article.prePubl && this.article.prePubl.reviewer) {
      return this.article.prePubl.reviewer
    }

    return null
  }

  get cardTitle() {
    switch (this.article.itemType) {
      case 'Reviewed':
        return 'Review'
      case 'Pending':
        return 'Pending - Not Public'
      default:
        return this.article.itemType
    }
  }

  get internal() {
    return this.$store.state.AppConfigModule.appConfig.internal
  }

  get instCode() {
    return this.$store.getters.appConfig.institution.code
  }

  get leavingmsnurl() {
    if (this.instCode.trim() === 'CWREU*441') {
      return this.article.details.summaryUrl
    } else {
      return `https://mathscinet.ams.org/mathscinet/leavingmsn?url=${this.encodedSummaryUrl}`
    }
  }

  linkToAuthorPage(authorId: number) {
    return authorId && authorId > 0 ? 'router-link' : 'span'
  }

  leavingmsn(redirectUrl) {
    LeavingMSNAPI.leavingmsn(redirectUrl, this.instCode)
  }
}
